import { AnimationEvent } from '@angular/animations';
import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import {
  CdkDialogContainer,
  Dialog,
  DialogConfig,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  Optional,
} from '@angular/core';

import { IconsModule } from '../../../icon/icon.module';
import { updateAllOpenedDrawersPosition } from '../../helpers/drawers-positions.helper';
import { DRAWER_DATA } from '../../services/drawer-tokens';
import { slideOverAnimation } from './slide-over.animation';

@Component({
  selector: 't-drawer',
  templateUrl: `./drawer.component.html`,
  standalone: true,
  imports: [DialogModule, PortalModule, IconsModule],
  animations: [slideOverAnimation.slideInOut],
  styles: [
    `
      :host {
        width: 100%;
        display: block;
      }
    `,
  ],
})
export class DrawerComponent extends CdkDialogContainer {
  constructor(
    private dialog: Dialog,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(DRAWER_DATA) public drawerData: any,
    public dialogRef: DialogRef<unknown>,
    // Required for extending CDK Dialog container
    private elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: unknown,
    dialogConfig: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      dialogConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor
    );
  }

  @HostBinding('@slideInOut')
  animationState: 'void' | 'open' | 'closed' = 'open';

  animationStateChanged = new EventEmitter<AnimationEvent>();

  @HostListener('@slideInOut.start', ['$event'])
  onAnimationStart($event: AnimationEvent) {
    if ($event.fromState === 'open' && $event.toState === 'closed') {
      updateAllOpenedDrawersPosition([...this.dialog.openDialogs], 'close');
    }
    this.animationStateChanged.emit($event);
  }

  @HostListener('@slideInOut.done', ['$event'])
  onAnimationDone($event: AnimationEvent) {
    this.animationStateChanged.emit($event);
  }

  startExitAnimation() {
    this.animationState = 'closed';
  }
}
