<button
  class="font-sans uppercase rounded border size-{{ size }} variant-{{ variant }} whitespace-nowrap"
  [ngClass]="{
    disabled: disabled,
    loading: loading,
    filled: filled,
    selected: selected,
    'inline-block': !isFullWidth,
    'block w-full': isFullWidth
  }"
  [attr.disabled]="disabled || loading ? true : null"
  [attr.variant]="variant"
  (click)="onClick.emit($event)"
  (focus)="onFocus.emit($event)"
  (blur)="onBlur.emit($event)">
  <span
    class="flex items-center uppercase"
    [ngClass]="{
      'justify-center': isFullWidth,
      'flex-row-reverse': icon && iconPos === 'right'
    }">
    <ng-container *ngIf="icon">
      <t-icon [key]="icon" color="inherit" [size]="size"></t-icon>
    </ng-container>
    <span
      #content
      *ngIf="hasContent"
      class="font-semibold"
      [ngClass]="{
        'mr-2': icon && iconPos === 'right',
        'ml-2': icon && iconPos === 'left'
      }">
      <ng-content></ng-content>
    </span>
  </span>
</button>
