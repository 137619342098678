import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIcons, SvgIconComponent } from '@ngneat/svg-icon';

export type Colors = 'primary' | 'secondary' | 'inherit';
export type Sizes = 'large' | 'medium' | 'small' | 'tiny' | 'tiniest' | 'micro';

@Component({
  selector: 't-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent],
})
export class IconComponent {
  private sizesMap: { [key in Sizes]: number } = {
    large: 30,
    medium: 24,
    small: 20,
    tiny: 16,
    tiniest: 12,
    micro: 8,
  };
  sizeInPx = this.sizesMap.medium; // Default to 24px

  private colorsMap: { [key in Colors]: string } = {
    primary: '#000000',
    secondary: '#e3e3e3',
    inherit: 'inherit',
  };
  colorInHex = this.colorsMap.primary; // Default to black

  @Input() key: SvgIcons = 'info-circle';

  @Input()
  set color(val: Colors) {
    this.colorInHex = this.colorsMap[val];
  }

  @Input()
  set size(val: Sizes) {
    this.sizeInPx = this.sizesMap[val];
  }
}
