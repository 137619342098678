import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ValidationErrors } from '@angular/forms';
import { IconsModule } from '../../icon/icon.module';

@Component({
  selector: 't-field-error',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './field-error.component.html',
})
export class FieldErrorComponent {
  errorMsgList: string[] = [];

  @Input() controlName!: AbstractControl | AbstractControlDirective;

  errorMessage = {
    required: () => `This field is required`,
    email: () => `This field should be a valid email address`,
    maxlength: (params: ValidationErrors) =>
      `Maximum ${params['requiredLength']} characters are allowed`,
    minlength: (params: ValidationErrors) =>
      `Minimum ${params['requiredLength']} characters are required`,
    pattern: () => `Pattern is not matched`,
    phoneNumberInvalid: () => `Phone number is invalid`,
  };

  listErrors() {
    if (!this.controlName) return [];
    if (this.controlName.errors) {
      this.errorMsgList = [];
      Object.keys(this.controlName.errors).map((error) => {
        if (this.controlName.touched || this.controlName.dirty) {
          if (
            this.controlName.errors &&
            this.errorMessage[error as keyof typeof this.errorMessage]
          ) {
            return this.errorMsgList.push(
              this.errorMessage[error as keyof typeof this.errorMessage](
                this.controlName.errors[error]
              )
            );
          }
        }
        return null;
      });
      return this.errorMsgList;
    } else {
      return [];
    }
  }
}
