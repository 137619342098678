/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogRef } from '@angular/cdk/dialog';
import { BasePortalOutlet } from '@angular/cdk/portal';

interface BasePortalOutletWithElementRef extends BasePortalOutlet {
  _elementRef: {
    nativeElement: HTMLElement;
  };
}

export const updateAllOpenedDrawersPosition = (
  openDialogs: DialogRef<any, any>[],
  targetState: 'open' | 'close'
) => {
  for (const [idx, dialog] of openDialogs.entries()) {
    const idxSubtract = targetState === 'open' ? 1 : 2;

    // TODO: Make the width dynamic and calculate it based on the drawer width
    const newPosition = (openDialogs.length - idxSubtract - idx) * 448;

    const element$ = dialog.containerInstance as BasePortalOutletWithElementRef;
    const element = element$._elementRef.nativeElement;
    element.style.setProperty('transition', 'all 300ms ease-in-out');
    element.style.setProperty('transform', `translateX(-${newPosition}px)`);
  }
};
