<div
  class="grid w-full grid-cols-1 items-stretch gap-x-2"
  [ngClass]="{
    'grid-cols-1': skeletonColumns.length === 1,
    'grid-cols-2': skeletonColumns.length === 2,
    'grid-cols-3': skeletonColumns.length === 3,
    'grid-cols-4': skeletonColumns.length === 4,
    'grid-cols-5': skeletonColumns.length >= 5
  }">
  <div *ngFor="let column of skeletonColumns">
    <div class="animate-pulse space-y-2">
      <div
        *ngFor="let row of skeletonRows"
        class="block w-full rounded-lg p-8"
        [ngClass]="{
          'p-2': rowsHeight === 'small',
          'p-5': rowsHeight === 'medium',
          'p-8': rowsHeight === 'large',
          'p-10': rowsHeight === 'huge',
          'bg-grey-15': color === 'light',
          'bg-grey-25': color === 'dark'
        }"></div>
    </div>
  </div>
</div>
