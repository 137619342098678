import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheOptions } from './cache-options.model';
import { CacheService } from './http-cache.service';

export const CACHE_OPTIONS = new HttpContextToken<CacheOptions>(() => {
  return {
    ttl: 0,
  };
});

@Injectable()
export class HttpCachingInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cacheOptions = req.context.get(CACHE_OPTIONS);

    if (req.method !== 'GET' || !cacheOptions.ttl) {
      return next.handle(req);
    }

    const cacheKey = `${req.urlWithParams}`;
    const cachedResponse = this.cacheService.get(cacheKey);

    if (cacheOptions.forceUpdate) {
      this.cacheService.clear(cacheKey);
    }

    // Return cached response if exists and not expired
    if (cachedResponse && cachedResponse.expiresOn > Date.now()) {
      return of(new HttpResponse({ body: cachedResponse.data, status: 200 }));
    }

    // If no cache, proceed with the actual HTTP call
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && cacheOptions.ttl > 0 && event.status === 200) {
          this.cacheService.set(cacheKey, {
            data: event.body,
            expiresOn: Date.now() + cacheOptions.ttl,
          });
        }
      })
    );
  }
}
