import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type Sizes = 'small' | 'medium' | 'large';

@Component({
  selector: 't-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  @Input() size: Sizes = 'medium';
}
