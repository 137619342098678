import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { getLoadingStatus, LoadingState } from '@troyai/shared/state';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { LeadsApiService } from '../leads-api.service';
import { LeadListItem } from '../models/lead.model';
import { LeadsStateModel } from './leads-state.model';
import {
  GetLeadsList,
  GetSingleLead,
  ReachOutToLead,
  ReviseLeadEmailContent,
  SubmitLeadApprovalFeedback,
  SubmitLeadRejectionFeedback,
} from './leads.actions';

@State<LeadsStateModel>({
  name: 'leadsState',
  defaults: {
    leads: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    selectedLead: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
    reachingOutLoading: {
      result: null,
      loadingStatus: LoadingState.INITIAL,
    },
  },
})
@Injectable()
export class LeadsState {
  constructor(private leadsApiService: LeadsApiService) {}

  @Selector()
  static leadsList(state: LeadsStateModel) {
    return state.leads.result;
  }

  @Selector()
  static leadsListLoadingStatus(state: LeadsStateModel) {
    return getLoadingStatus(state.leads.loadingStatus);
  }

  @Selector()
  static selectedLead(state: LeadsStateModel) {
    return state.selectedLead.result;
  }

  @Selector()
  static selectedLeadLoadingStatus(state: LeadsStateModel) {
    return getLoadingStatus(state.selectedLead.loadingStatus);
  }

  @Selector()
  static reachingOutLoadingStatus(state: LeadsStateModel) {
    return getLoadingStatus(state.reachingOutLoading.loadingStatus);
  }

  @Action(GetLeadsList) getLeadsList(ctx: StateContext<LeadsStateModel>, action: GetLeadsList) {
    ctx.patchState({
      leads: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService.getLeads(action.projectId).pipe(
      tap((result) => {
        ctx.patchState({
          leads: {
            result,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((error) => {
        ctx.patchState({
          leads: {
            result: null,
            loadingStatus: LoadingState.ERRORED,
          },
        });
        return throwError(() => error);
      })
    );
  }

  @Action(GetSingleLead) getLead(ctx: StateContext<LeadsStateModel>, action: GetSingleLead) {
    ctx.patchState({
      selectedLead: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService.getSingleLead(action.payload.leadId, action.payload.projectId).pipe(
      tap((result) => {
        ctx.patchState({
          selectedLead: {
            result,
            loadingStatus: LoadingState.LOADED,
          },
        });
      }),
      catchError((error) => {
        ctx.patchState({
          selectedLead: {
            result: null,
            loadingStatus: LoadingState.ERRORED,
          },
        });
        return throwError(() => error);
      })
    );
  }

  @Action(SubmitLeadRejectionFeedback) submitLeadRejectionFeedback(
    { patchState, getState }: StateContext<LeadsStateModel>,
    action: SubmitLeadRejectionFeedback
  ) {
    patchState({
      selectedLead: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService
      .submitLeadFeedback(
        action.payload.leadId,
        action.payload.projectId,
        action.payload.approved,
        action.payload.reasonId,
        action.payload.message
      )
      .pipe(
        tap(() => {
          const leads = getState().leads.result;
          const updatedLeads = leads?.map((lead) => {
            if (lead.id === action.payload.leadId) {
              return {
                ...lead,
                status: action.payload.approved ? 'approved' : 'rejected',
              } as LeadListItem;
            }
            return lead;
          });

          if (updatedLeads) {
            patchState({
              leads: {
                result: updatedLeads,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }
        })
      );
  }

  @Action(SubmitLeadApprovalFeedback) submitLeadApprovalFeedback(
    { patchState, getState }: StateContext<LeadsStateModel>,
    action: SubmitLeadApprovalFeedback
  ) {
    patchState({
      selectedLead: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService
      .reviseLeadContent(action.payload.leadId, action.payload.projectId, action.payload.content)
      .pipe(
        mergeMap(() =>
          this.leadsApiService.submitLeadFeedback(
            action.payload.leadId,
            action.payload.projectId,
            true,
            null
          )
        ),
        tap(() => {
          const leads = getState().leads.result;
          const updatedLeads = leads?.map((lead) => {
            if (lead.id === action.payload.leadId) {
              return {
                ...lead,
                status: 'approved',
              } as LeadListItem;
            }
            return lead;
          });

          if (updatedLeads) {
            patchState({
              leads: {
                result: updatedLeads,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }
        })
      );
  }

  @Action(ReviseLeadEmailContent)
  reviseLeadEmailContent(
    { patchState, getState }: StateContext<LeadsStateModel>,
    action: ReviseLeadEmailContent
  ) {
    patchState({
      selectedLead: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService
      .reviseLeadContent(action.payload.leadId, action.payload.projectId, action.payload.content)
      .pipe(
        tap(() => {
          const leads = getState().leads.result;
          const updatedLeads = leads?.map((lead) => {
            if (lead.id === action.payload.leadId) {
              return {
                ...lead,
                generated_content: action.payload.content.body,
                generated_subject: action.payload.content.subject,
              } as LeadListItem;
            }
            return lead;
          });

          if (updatedLeads) {
            patchState({
              leads: {
                result: updatedLeads,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }
        })
      );
  }

  @Action(ReachOutToLead)
  reachOutToLead({ getState, patchState }: StateContext<LeadsStateModel>, action: ReachOutToLead) {
    patchState({
      reachingOutLoading: {
        result: null,
        loadingStatus: LoadingState.LOADING,
      },
    });

    return this.leadsApiService
      .reachOutToLead(action.payload.leadId, action.payload.projectId, action.payload.content)
      .pipe(
        tap(() => {
          const leads = getState().leads.result;
          const updatedLeads = leads?.map((lead) => {
            if (lead.id === action.payload.leadId) {
              return {
                ...lead,
                status: 'approved',
              } as LeadListItem;
            }
            return lead;
          });

          if (updatedLeads) {
            patchState({
              leads: {
                result: updatedLeads,
                loadingStatus: LoadingState.LOADED,
              },
            });
          }

          patchState({
            reachingOutLoading: {
              result: null,
              loadingStatus: LoadingState.LOADED,
            },
          });
        })
      );
  }
}
