export type LeadStatus = 'pending' | 'approved' | 'rejected';

export interface LeadListItem {
  id: string;
  provider_id: string;
  first_name: string;
  last_name: string;
  picture_url: string;
  linkedin_url: string;
  role: string;
  email: string;
  company_name: string;
  updated_at: string;
  created_at: string;
  delivery_date: string;
  location: LeadLocation;
  status: LeadStatus;
}

export interface SingleLeadItem {
  id: string;
  provider_id: string;
  first_name: string;
  last_name: string;
  picture_url: string;
  linkedin_url: string;
  role: string;
  email: string;
  company_name: string;
  updated_at: string;
  created_at: string;
  delivery_date: string;
  project_id: string;
  location: LeadLocation;
  generated_content: string;
  generated_subject: string;
}

export interface LeadLocation {
  city: string;
  state: string;
  country: string;
}

export enum LeadActionType {
  Reach = 'reach',
  Reject = 'reject',
}

export interface LeadEmailContent {
  body: string;
  subject: string;
}
