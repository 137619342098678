import { LeadEmailContent } from '../models/lead.model';

export class GetLeadsList {
  static readonly type = '[Leads] Let leads list';
  constructor(public projectId: string) {}
}

export class GetSingleLead {
  static readonly type = '[Leads] Get single lead';
  constructor(public payload: { leadId: string; projectId: string }) {}
}

export class SubmitLeadRejectionFeedback {
  static readonly type = '[Leads] Submit lead rejection feedback';
  constructor(
    public payload: {
      leadId: string;
      projectId: string;
      message?: string;
      approved: boolean;
      reasonId: number;
    }
  ) {}
}

export class SubmitLeadApprovalFeedback {
  static readonly type = '[Leads] Submit lead approval feedback';
  constructor(
    public payload: {
      leadId: string;
      projectId: string;
      content: LeadEmailContent;
    }
  ) {}
}

export class ReviseLeadEmailContent {
  static readonly type = '[Leads] Revise lead email content';
  constructor(
    public payload: {
      leadId: string;
      projectId: string;
      content: LeadEmailContent;
    }
  ) {}
}

export class ReachOutToLead {
  static readonly type = '[Leads] Reach out to lead';
  constructor(
    public payload: {
      leadId: string;
      projectId: string;
      content: LeadEmailContent;
    }
  ) {}
}
