<div class="relative block w-full">
  <label *ngIf="label" [for]="id">{{ label }}</label>

  <ng-container *ngIf="inputType === 'textarea'; else regularInput">
    <textarea
      class="size-{{ size }} variant-{{ variant }} block"
      [id]="id"
      [placeholder]="placeholder"
      [ngModel]="input"
      [class.has-error]="hasError"
      (ngModelChange)="onChange($event)"></textarea>
  </ng-container>
  <ng-template #regularInput>
    <div class="relative block">
      <input
        class="size-{{ size }} variant-{{ variant }} block w-full"
        [id]="id"
        [type]="inputType"
        [ngModel]="input"
        [placeholder]="placeholder"
        [ngClass]="{
          'has-error': hasError,
          'has-icon': icon
        }"
        [attr.readonly]="readonly ? '' : null"
        [attr.autofocus]="autofocus ? '' : null"
        #textInput
        (ngModelChange)="onChange($event)" />
      <div *ngIf="icon" class="icon text-grey-70">
        <t-icon [key]="icon" color="inherit" size="small"></t-icon>
      </div>
    </div>
  </ng-template>
</div>
