import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DrawerService } from './services/drawer.service';

@NgModule({
  providers: [DrawerService],
  imports: [CommonModule, DrawerComponent, OverlayModule],
})
export class DrawerModule {}
