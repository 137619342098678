// @ts-nocheck
/* eslint-disable */
import { addIcon } from './add';
import { adsClickIcon } from './ads-click';
import { alarmSmartWakeIcon } from './alarm-smart-wake';
import { allOutIcon } from './all-out';
import { arrowDropDownIcon } from './arrow-drop-down';
import { arrowDropUpIcon } from './arrow-drop-up';
import { articleIcon } from './article';
import { bookmarkAddedIcon } from './bookmark-added';
import { callIcon } from './call';
import { checkCircleIcon } from './check-circle';
import { checkIcon } from './check';
import { closeIcon } from './close';
import { deleteForeverIcon } from './delete-forever';
import { deleteIcon } from './delete';
import { detectorStatusIcon } from './detector-status';
import { doDisturbOnIcon } from './do-disturb-on';
import { earthquakeIcon } from './earthquake';
import { editNoteIcon } from './edit-note';
import { errorIcon } from './error';
import { eventIcon } from './event';
import { expandMoreIcon } from './expand-more';
import { faceIcon } from './face';
import { favoriteBorderIcon } from './favorite-border';
import { favoriteIcon } from './favorite';
import { flagIcon } from './flag';
import { helpIcon } from './help';
import { homeHealthIcon } from './home-health';
import { homePinIcon } from './home-pin';
import { hourglassIcon } from './hourglass';
import { infoCircleIcon } from './info-circle';
import { keyboardArrowDownIcon } from './keyboard-arrow-down';
import { keyboardArrowLeftIcon } from './keyboard-arrow-left';
import { keyboardArrowRightIcon } from './keyboard-arrow-right';
import { keyboardArrowUpIcon } from './keyboard-arrow-up';
import { keyboardDoubleArrowLeftIcon } from './keyboard-double-arrow-left';
import { keyboardDoubleArrowRightIcon } from './keyboard-double-arrow-right';
import { localHospitalIcon } from './local-hospital';
import { locationOnIcon } from './location-on';
import { lockIcon } from './lock';
import { logoutIcon } from './logout';
import { mailIcon } from './mail';
import { menuIcon } from './menu';
import { moreVertIcon } from './more-vert';
import { notificationsActiveIcon } from './notifications-active';
import { openInNewIcon } from './open-in-new';
import { paidIcon } from './paid';
import { personIcon } from './person';
import { printIcon } from './print';
import { searchIcon } from './search';
import { sendIcon } from './send';
import { sentimentSatisfiedIcon } from './sentiment-satisfied';
import { sortByAlphaIcon } from './sort-by-alpha';
import { starsIcon } from './stars';
import { storefrontIcon } from './storefront';
import { tourIcon } from './tour';
import { tuneIcon } from './tune';
import { unfoldMoreIcon } from './unfold-more';
export const materialIcons = [
  addIcon,
  adsClickIcon,
  alarmSmartWakeIcon,
  allOutIcon,
  arrowDropDownIcon,
  arrowDropUpIcon,
  articleIcon,
  bookmarkAddedIcon,
  callIcon,
  checkCircleIcon,
  checkIcon,
  closeIcon,
  deleteForeverIcon,
  deleteIcon,
  detectorStatusIcon,
  doDisturbOnIcon,
  earthquakeIcon,
  editNoteIcon,
  errorIcon,
  eventIcon,
  expandMoreIcon,
  faceIcon,
  favoriteBorderIcon,
  favoriteIcon,
  flagIcon,
  helpIcon,
  homeHealthIcon,
  homePinIcon,
  hourglassIcon,
  infoCircleIcon,
  keyboardArrowDownIcon,
  keyboardArrowLeftIcon,
  keyboardArrowRightIcon,
  keyboardArrowUpIcon,
  keyboardDoubleArrowLeftIcon,
  keyboardDoubleArrowRightIcon,
  localHospitalIcon,
  locationOnIcon,
  lockIcon,
  logoutIcon,
  mailIcon,
  menuIcon,
  moreVertIcon,
  notificationsActiveIcon,
  openInNewIcon,
  paidIcon,
  personIcon,
  printIcon,
  searchIcon,
  sendIcon,
  sentimentSatisfiedIcon,
  sortByAlphaIcon,
  starsIcon,
  storefrontIcon,
  tourIcon,
  tuneIcon,
  unfoldMoreIcon,
];
