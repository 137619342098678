<div class="relative">
  @if (label) {
    <label [for]="id" class="text-grey-50 mb-2 block text-sm">{{ label }}</label>
  }
  <ng-select
    #ngSelect
    [id]="id"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [loading]="loading"
    [typeahead]="observableInput$"
    [placeholder]="placeholder || 'Select...'"
    (change)="select($event)"
    [(ngModel)]="selected"
    (clear)="clear()"
    class="t-select"
    [ngClass]="{
      'has-error': ngControl.errors && (ngControl.dirty || ngControl.touched)
    }">
  </ng-select>
</div>
