import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_BASE_URL } from './rest-api-base-url-token';
import { RestApiResponse } from './rest-api-response.interface';

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RestApiClientService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  get<T>(
    path: string,
    options?: {
      context?: HttpContext;
      params?: HttpParams | { [param: string]: string | string[] };
      headers?: HttpHeaders | { [header: string]: string | string[] };
    }
  ): Observable<T> {
    return this.http.get<RestApiResponse<T>>(`${this.apiBaseUrl}/${path}`, options).pipe(
      map((response) => {
        // TODO: Remove this once the API is updated to return the correct response (should always have data field)
        return response ? response.data : (null as T);
      })
    );
  }

  post<T>(path: string, payload: unknown, httpOptions?: HttpOptions): Observable<T> {
    if (payload instanceof FormData) {
      return this.http
        .post<RestApiResponse<T>>(`${this.apiBaseUrl}/${path}`, payload)
        .pipe(map((response) => response.data));
    }

    if (payload instanceof HttpParams) {
      return this.http
        .post<RestApiResponse<T>>(`${this.apiBaseUrl}/${path}`, payload, this.httpOptions)
        .pipe(map((response) => response.data));
    }

    return this.http
      .post<
        RestApiResponse<T>
      >(`${this.apiBaseUrl}/${path}`, JSON.stringify(payload), httpOptions || this.httpOptions)
      .pipe(map((response) => response.data));
  }

  put<T>(path: string, payload: unknown): Observable<T> {
    return this.http.put<T>(
      `${this.apiBaseUrl}/${path}`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  patch<T>(path: string, payload: unknown, httpOptions?: HttpOptions): Observable<T> {
    if (payload instanceof HttpParams) {
      return this.http.patch<T>(`${this.apiBaseUrl}/${path}`, payload, this.httpOptions);
    }

    return this.http
      .patch<
        RestApiResponse<T>
      >(`${this.apiBaseUrl}/${path}`, JSON.stringify(payload), httpOptions || this.httpOptions)
      .pipe(map((response) => response.data));
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${this.apiBaseUrl}/${path}`, this.httpOptions);
  }
}
