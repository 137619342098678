<header class="shadow-small flex w-full items-center justify-between bg-white p-5">
  <div class="flex items-center gap-x-5">
    <div
      (click)="closeDrawer()"
      class="bg-grey-20 hover:bg-grey-25 cursor-pointer rounded-full p-2">
      <t-icon key="close" size="tiniest" />
    </div>
    <strong class="text-grey-70 text-sm font-semibold">{{ titleText }}</strong>
  </div>

  <div>
    <ng-content />
  </div>
</header>
