import { NgModule } from '@angular/core';
import { SvgIconComponent, provideSvgIcons } from '@ngneat/svg-icon';

import { IconComponent } from '../icon/icon.component';
import { customIcons, materialIcons } from '@troyai/icons';

@NgModule({
  providers: [provideSvgIcons([...materialIcons, ...customIcons])],
  imports: [SvgIconComponent, IconComponent],
  exports: [IconComponent],
})
export class IconsModule {}
