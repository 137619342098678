import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { switchMap, timer } from 'rxjs';
import { LeadEmailContent, LeadListItem, SingleLeadItem } from './models/lead.model';

@Injectable({
  providedIn: 'root',
})
export class LeadsApiService {
  constructor(private restApiService: RestApiClientService) {}

  getLeads(projectId: string) {
    return this.restApiService.get<LeadListItem[]>(
      `projects/${projectId}/leads?time-interval=LAST_DAY`
    );
  }

  getSingleLead(leadId: string, projectId: string) {
    return this.restApiService.get<SingleLeadItem>(`projects/${projectId}/leads/${leadId}`);
  }

  submitLeadFeedback(
    leadId: string,
    projectId: string,
    approved: boolean,
    reasonId: number | null,
    message?: string
  ) {
    return timer(500).pipe(
      switchMap(() =>
        this.restApiService.post(`projects/${projectId}/leads/${leadId}/feedback`, {
          approved,
          reason_id: reasonId,
          feedback_message: message,
        })
      )
    );
  }

  reviseLeadContent(leadId: string, projectId: string, content: LeadEmailContent) {
    return timer(500).pipe(
      switchMap(() =>
        this.restApiService.patch(`projects/${projectId}/leads/${leadId}/content`, {
          revised_content: content.body,
          revised_subject: content.subject,
        })
      )
    );
  }

  reachOutToLead(leadId: string, projectId: string, content: LeadEmailContent) {
    return timer(500).pipe(
      switchMap(() =>
        this.restApiService.post(`projects/${projectId}/leads/${leadId}/contact`, {
          revised_content: content.body,
          revised_subject: content.subject,
        })
      )
    );
  }
}
