import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconsModule } from '../../../icon/icon.module';
import { DrawerRef } from '../../services/drawer-ref.class';

@Component({
  selector: 't-drawer-header',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './drawer-header.component.html',
})
export class DrawerHeaderComponent {
  constructor(private drawerRef: DrawerRef<unknown>) {}

  @Input() titleText = '';

  closeDrawer() {
    this.drawerRef.closeDrawer();
  }
}
