import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideOverAnimation: {
  readonly slideInOut: AnimationTriggerMetadata;
} = {
  slideInOut: trigger('slideInOut', [
    state('void', style({ transform: 'translateX(100%)' })),
    state('open', style({ transform: 'translateX(0)' })),
    state('closed', style({ transform: 'translateX(100%)' })),
    transition('void => open', [animate('300ms ease-in-out')]),
    transition('open => closed', [animate('300ms ease-in-out')]),
  ]),
};
