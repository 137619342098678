<div class="flex h-full flex-col justify-between">
  <div class="flex-none">
    <ng-content select="t-drawer-header" />
  </div>
  <div class="flex-auto overflow-y-auto p-8">
    <ng-content />
  </div>
  <div class="flex-none">
    <ng-content select="t-drawer-footer" />
  </div>
</div>
