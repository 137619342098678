/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';
import { IconsModule } from '../icon/icon.module';

type Variants = 'primary' | 'secondary' | 'neutral';
type Sizes = 'large' | 'medium' | 'small' | 'tiny';
type IconPosition = 'left' | 'right';

@Component({
  selector: 't-button',
  standalone: true,
  imports: [CommonModule, IconsModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  @ViewChild('content') content!: ElementRef;

  @Input() variant: Variants = 'primary';
  @Input() filled = false;
  @Input() size: Sizes = 'medium';
  @Input() isFullWidth = false;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() icon: SvgIcons | null = null;
  @Input() iconPos: IconPosition = 'left';
  @Input() selected = false;

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter();

  hasContent = true;

  ngAfterViewInit() {
    if (!this.content.nativeElement.innerHTML.length) {
      this.hasContent = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
