/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SvgIcons } from '@ngneat/svg-icon';
import { IconsModule } from '../icon/icon.module';

type Sizes = 'thick' | 'narrow';
type InputType = 'text' | 'email' | 'phone' | 'password' | 'textarea' | 'tel';
type Variants = 'default' | 'borderless';

@Component({
  selector: 't-input-text',
  standalone: true,
  imports: [CommonModule, FormsModule, IconsModule],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements ControlValueAccessor, AfterViewInit {
  constructor(@Optional() private controlContainer: ControlContainer) {}

  @ViewChild('textInput') private _inputElement: ElementRef<HTMLInputElement> | undefined;

  @Input() label = '';
  @Input() inputType: InputType = 'text';
  @Input() id = '';
  @Input() size: Sizes = 'thick';
  @Input() icon: SvgIcons | null = null;
  @Input() formControl?: FormControl;
  @Input() formControlName = '';
  @Input() placeholder = '';
  @Input() variant: Variants = 'default';
  @Input() readonly = false;
  @Input() autofocus = false;

  get control() {
    return (
      this.formControl ||
      (this.controlContainer?.control
        ? this.controlContainer?.control.get(this.formControlName)
        : null)
    );
  }

  hasError = false;

  input = '';
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = (newValue: any) => {
      fn(newValue);
      this.input = newValue;
      if (this.control) {
        this.hasError =
          (this.control.touched || this.control.dirty) && this.control.errors !== null;
      }
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string) {
    this.input = input;
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this._inputElement) {
      this._inputElement.nativeElement.focus();
    }
  }
}
