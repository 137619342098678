/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Observable, Subject, filter, take } from 'rxjs';
import { DrawerComponent } from '../components/drawer/drawer.component';

@Injectable({ providedIn: 'root' })
export class DrawerRef<C> {
  constructor(private readonly dialogRef: DialogRef<unknown, C>) {
    this.dialogRef.backdropClick.pipe(take(1)).subscribe(() => this.closeDrawer());
  }

  private afterClosedSubject = new Subject<any>();
  private beforeClosingSubject = new Subject<void>();

  public closeDrawer(result?: unknown) {
    const containerInstance = this.dialogRef.containerInstance as DrawerComponent;

    this.beforeClosingSubject.next();
    this.beforeClosingSubject.complete();

    containerInstance.animationStateChanged
      .pipe(
        filter((animationEvent) => animationEvent.phaseName === 'start'),
        take(1)
      )
      .subscribe(() => {
        this.dialogRef.overlayRef.detachBackdrop();
      });

    containerInstance.animationStateChanged
      .pipe(
        filter(
          (animationEvent) =>
            animationEvent.phaseName === 'done' && animationEvent.toState === 'closed'
        ),
        take(1)
      )
      .subscribe(() => {
        this.dialogRef.close(result);
      });

    containerInstance.startExitAnimation();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();
  }

  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }

  public beforeClosing(): Observable<void> {
    return this.beforeClosingSubject.asObservable();
  }
}
