import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DrawerComponent } from '../components/drawer/drawer.component';
import { updateAllOpenedDrawersPosition } from '../helpers/drawers-positions.helper';
import { DrawerRef } from './drawer-ref.class';
import { DRAWER_DATA } from './drawer-tokens';

export interface DrawerConfig extends DialogConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  constructor(
    private overlay: Overlay,
    public dialog: Dialog
  ) {}

  open<C>(component: ComponentType<C>, config?: DrawerConfig) {
    const dialogRef = this.dialog.open(component, {
      container: {
        type: DrawerComponent,
        providers: () => [
          {
            provide: DRAWER_DATA,
            useValue: config,
          },
        ],
      },
      providers: (dialogRef) => {
        const drawerRef = new DrawerRef(dialogRef);
        return [
          {
            provide: DrawerRef,
            useValue: drawerRef,
          },
        ];
      },
      width: config?.width || '448px',
      disableClose: true,
      positionStrategy: this.overlay.position().global().end(),
      data: config?.data || {},
    });

    updateAllOpenedDrawersPosition([...this.dialog.openDialogs], 'open');

    return dialogRef;
  }
}
