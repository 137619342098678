import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type RowsHeights = 'small' | 'medium' | 'large' | 'huge';
type Colors = 'light' | 'dark';

@Component({
  selector: 't-skeleton-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent {
  @Input() rows = 1;
  @Input() columns = 1;
  @Input() rowsHeight: RowsHeights = 'large';
  @Input() color: Colors = 'light';

  get skeletonRows() {
    return Array.from({ length: this.rows });
  }

  get skeletonColumns() {
    const maxLength = Math.min(this.columns, 5);
    return Array.from({ length: maxLength });
  }
}
